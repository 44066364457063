import { useContext } from "react";

import { PurchaseContext } from "~/hooks/purchase/PurchaseProvider";

export const useUserPurchases = () => {
  const { loadingUserPurchases, userPurchases, hasUserPurchasedProduct } =
    useContext(PurchaseContext);
  return { loadingUserPurchases, userPurchases, hasUserPurchasedProduct };
};

export const useAllUserPurchaseFunctions = () => {
  return useContext(PurchaseContext);
};
