export const isScrollAvailable = (element: any) => {
  if (element !== null && element?.scrollHeight - element?.clientHeight > 0) {
    var tolerance = 5;
    return element.scrollTop + tolerance < element.scrollHeight - element.clientHeight;
  }
  return false;
};

export const isElementVisibleOnScreen = (elm: any) => {
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
};
