export const SearchIcon = ({
  onClick,
}: {
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={onClick ? { cursor: "pointer" } : { cursor: "default" }}
  >
    <g>
      <path
        d="M15.504 17.1914L11.4354 13.0997C10.8938 13.5692 10.2622 13.9348 9.54069 14.1966C8.81921 14.4584 8.05148 14.5893 7.2375 14.5893C5.28457 14.5893 3.63176 13.9122 2.27906 12.5581C0.926352 11.2039 0.25 9.56988 0.25 7.65599C0.25 5.7421 0.927083 4.10807 2.28125 2.75391C3.63542 1.39974 5.27396 0.722656 7.19688 0.722656C9.11979 0.722656 10.7538 1.39974 12.099 2.75391C13.4441 4.10807 14.1167 5.74345 14.1167 7.66005C14.1167 8.43373 13.9903 9.18168 13.7375 9.90391C13.4847 10.6261 13.1056 11.3032 12.6 11.9352L16.6956 15.9998L15.504 17.1914ZM7.21042 12.9643C8.67743 12.9643 9.92439 12.4452 10.9513 11.407C11.9782 10.3688 12.4917 9.11849 12.4917 7.65599C12.4917 6.19349 11.9782 4.94314 10.9513 3.90495C9.92439 2.86675 8.67743 2.34766 7.21042 2.34766C5.72836 2.34766 4.46861 2.86675 3.43115 3.90495C2.39372 4.94314 1.875 6.19349 1.875 7.65599C1.875 9.11849 2.39372 10.3688 3.43115 11.407C4.46861 12.4452 5.72836 12.9643 7.21042 12.9643Z"
        fill="#AFAFAF"
      />
    </g>
    <defs>
      <clipPath id="clip0_1305_27603">
        <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
