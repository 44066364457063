import React, { useState, useRef, useEffect } from "react";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { ProductData } from "data/product/products";
import { AnimatedSpinner } from "studio-design-system";
import { useDebounce } from "usehooks-ts";

import { CreatorCheck } from "~/components/common/CreatorInfo/CreatorCheck";
import { SearchIcon } from "~/components/common/Icons/SearchIcon";
import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";
import { Divider } from "~/components/videoProductPage/common/Divider/Divider";
import { useUserPurchases } from "~/hooks/purchase/useUserPurchases";
import { useSearchProducts } from "~/hooks/useSearchProducts";
import { getResponsiveImageURL } from "~/util/cloudinary-utils";
import { getProductWatchPath } from "~/util/stringUtils";

import TwitterIconNew from "../Icons/TwitterIconNew";
import * as styles from "./SearchBar.css";

interface Props {
  isOpen: boolean;
  exceptions: React.MutableRefObject<null>[];
  children?: React.ReactNode;
  onOutsideClick: () => void;
}

const SearchResultItem = ({
  item,
  hasUserPurchasedItem,
  isMusicLibrary = false,
}: {
  item: ProductData;
  hasUserPurchasedItem: boolean;
  isMusicLibrary: boolean;
}) => {
  const getClickPathForItem = () => {
    if (isMusicLibrary) {
      return `/school/music/library/${item.id}`;
    }
    if (hasUserPurchasedItem) {
      return getProductWatchPath(item);
    }
    return `/classes/${item.storefrontSlug}/${item.classSlug}`;
  };

  return (
    <Link href={getClickPathForItem()} passHref>
      <a>
        <div
          className={classNames(styles.searchResultItemContainer, {
            [styles.searchResultItemContainerDark]: isMusicLibrary,
          })}
        >
          <div className={styles.searchResultItemTopInfoContainer}>
            <div className={styles.resultImageContainer}>
              <ImageWithBlur
                style={{ borderRadius: "8px" }}
                src={getResponsiveImageURL(item.productImageURL || "", "200") || ""}
                alt={`Profile picture of ${item.creatorName}`}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div
              className={classNames(styles.searchResultItemTextTopContainer, {
                [styles.searchResultItemTextTopContainerDark]: isMusicLibrary,
              })}
            >
              <div className={styles.title}>{item.title}</div>
              <div className={styles.creatorNameContainer}>
                <div className={styles.creatorName}>{item.creatorName}</div>
                <CreatorCheck />
              </div>
            </div>
          </div>
          <div className={styles.description}>{item.shortBio}</div>
        </div>
      </a>
    </Link>
  );
};

export const SearchBar = ({
  placeholder = "What do you want to learn?",
  redirectUrl = "/browse",
  handleCloseModal = () => {},
  isMusicLibrary = false,
}: {
  placeholder?: string;
  redirectUrl?: string;
  handleCloseModal?: () => void;
  isMusicLibrary?: boolean;
}): React.ReactElement => {
  const router = useRouter();
  const { query } = router;

  const [searchProductsQuery, setSearchProductsQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const searchComponentRef = useRef<HTMLDivElement>(null);
  const { hasUserPurchasedProduct } = useUserPurchases();
  const { searchResults, isLoadingSearchResults, searchError } = useSearchProducts(
    searchProductsQuery,
    isMusicLibrary ? ["Music"] : undefined,
  );
  const [showResults, setShowResults] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  useEffect(() => {
    setShowLoader(false);
  }, [query.search]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        inputRef.current.value != "" &&
        searchComponentRef.current &&
        !searchComponentRef.current.contains(event.target as Node)
      ) {
        // Call your hideToggle function here
        setSearchProductsQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.searchComponent} ref={searchComponentRef}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <input
        type="text"
        placeholder={placeholder}
        value={searchProductsQuery}
        onChange={(e) => {
          setSearchProductsQuery(e.target.value);
        }}
        onFocus={() => {
          setShowResults(true);
        }}
        onKeyDown={(e) => {
          console.log(e.code);
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            e.preventDefault();
            setShowResults(false);
            // NOT SHOW LOADER WHEN TEXT IS SAME AND ENTER HIT
            if (query.search != searchProductsQuery) {
              setShowLoader(true);
            }
            handleCloseModal();
            router.push(`${redirectUrl}?search=${searchProductsQuery}`);
          }
        }}
        // autoFocus={isEditingSearch}
        className={classNames(styles.searchInput, {
          [styles.searchInputWhite]: isMusicLibrary,
        })}
        ref={inputRef}
      />
      {showLoader && (
        <div className={styles.loaderContainer}>
          <AnimatedSpinner className={styles.loader} />
        </div>
      )}
      {searchProductsQuery.length > 0 && showResults && (
        <div className={styles.searchResultsContainer}>
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div
                key={result.objectID}
                className={classNames(styles.searchResultContainer, {
                  [styles.searchResultContainerDark]: isMusicLibrary,
                })}
              >
                <SearchResultItem
                  key={result.objectID}
                  item={result}
                  hasUserPurchasedItem={hasUserPurchasedProduct(result.objectID ?? "")}
                  isMusicLibrary={isMusicLibrary}
                />
                {index !== searchResults.length - 1 && <Divider marginBottom={0} marginTop={0} />}
              </div>
            ))
          ) : (
            <div className={styles.noResultsContainer}>
              <div className={styles.noResultsText}>No results found</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
