import { useAuth } from "../auth/useAuth";

declare global {
  interface Window {
    Intercom?: (action: string, options?: any) => void;
  }
}

export const useIntercomFeature = () => {
  const { user } = useAuth();

  const onQuestionClick = () => {
    if (!window) {
      return;
    }
    if (window.innerWidth < 600) {
      window.location.href = "mailto:support@studio.com?subject=Question";
    } else {
      if (!window.Intercom) {
        return;
      } else {
        window.Intercom("boot", {
          app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
          ...getIntercomUser(),
        });
        window.Intercom("show");
        window.Intercom("onHide", function () {
          window.Intercom?.("shutdown");
        });
      }
    }
  };

  const getIntercomUser = () => {
    if (user) {
      return {
        user_id: user.username,
        email: user.email,
        name: user.firstName,
      };
    }
    return undefined;
  };

  return { onQuestionClick };
};
