import React from "react";

import { theme } from "studio-design-system";

import * as styles from "./Divider.css";

export const Divider = ({
  marginTop,
  marginBottom,
  autoAlignSides,
  color = theme.colors.border.opaque,
}: {
  marginTop: number;
  marginBottom: number;
  autoAlignSides?: boolean;
  color?: string;
}) => {
  return (
    <div
      className={styles.dividerCss}
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        backgroundColor: color,
        ...(autoAlignSides && { marginLeft: "auto", marginRight: "auto" }),
      }}
    ></div>
  );
};
