import React from "react";

import classNames from "classnames";
import Head from "next/head";

import { Header } from "studio-design-system";

import {
  MUSIC_SCHOOL_DESCRIPTION,
  MUSIC_SCHOOL_IMAGE,
  MUSIC_SCHOOL_TITLE,
} from "~/components/musicHomepage/MusicHomepage";

import * as styles from "./MarketingHeader.css";

interface Props {
  children: React.ReactChild;
  hideBottomBorder?: boolean;
  musicHeader?: boolean;
  isSticky?: boolean;
  className?: string;
}

export const MarketingHeader = ({
  children,
  hideBottomBorder,
  isSticky,
  className,
  musicHeader,
}: Props) => {
  return (
    <div className={classNames(isSticky ? styles.musicStickyHeader : styles.stickyHeader)}>
      <Header
        className={classNames(
          styles.enabledBorder,
          className,
          hideBottomBorder && styles.hiddenBorder,
          styles.background,
          musicHeader && styles.musicHeader,
        )}
      >
        {children}
      </Header>
    </div>
  );
};
