export const classesTabs = ["All classes", "New releases", "Top rated", "Collections"];
export const musicLibraryTabs = ["Browse all", "My content"];

export const classesCategories = [
  "Music",
  "Performing Arts",
  "Baking",
  "Crafts & Making",
  "Visual Arts",
  "Filmmaking",
  "YouTubers",
];

export const sortedSearchOptions = [
  "All classes",
  "Music",
  "New releases",
  "Performing Arts",
  "Top rated",
  "Baking",
  "YouTubers",
  "Visual Arts",
  "Crafts & Making",
  "Filmmaking",
];

export const classesTopics = [
  "Songwriting",
  "Music Production",
  "Vocal Production",
  "Beatmaking",
  "Mixing & Mastering",
  "Sound Design",
  "Engineering",
  "Film Scoring",
  "Piano",
  "Guitar",
  "Singing",
  "Vocals",
  "Arranging",
  "A Cappella",
  "Rapping",
  "Toplining",
  "Collaboration",
  "Band",
  "Music Business",
  "Sync",
  "Film & TV",
  "Composition",
  "Musical Theatre",
  "Unique",
  "Cover Songs",
];

export const classesGenres = [
  "Acoustic",
  "Alternative",
  "Ambient",
  "Christian",
  "Classical",
  "Country",
  "Dance",
  "Disco",
  "EDM",
  "Electronic",
  "Experimental",
  "Funk",
  "Hip-Hop",
  "Indie",
  "Instrumental",
  "Folk",
  "Jazz",
  "Musical Theatre",
  "Nerdcore",
  "Rap",
  "Reggaeton",
  "Rock",
  "Soul",
  "Trap",
  "House",
  "K-Pop",
  "Latin",
  "Lofi",
  "Pop",
  "R&B",
  "Vintage",
];
