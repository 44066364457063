import { useEffect, useState } from "react";

import algoliasearch from "algoliasearch";

import { ProductData } from "data/product/products";

const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || "",
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || "",
);

const INDEX_NAME = "StudioProducts-dev"; // process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX
const index = client.initIndex(process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX || INDEX_NAME);

export function useSearchProducts(query: string, searchWithinCategories?: [string]) {
  const [searchResults, setResults] = useState<ProductData[]>([]);
  const [isLoadingSearchResults, setLoading] = useState(false);
  const [searchError, setError] = useState("");

  useEffect(() => {
    const performSearch = async () => {
      setLoading(true);
      try {
        const { hits } = (await index.search(query)) as { hits: ProductData[] };

        if (searchWithinCategories && searchWithinCategories.length > 0) {
          const categoriesSet = new Set(searchWithinCategories);

          const filteredHits = hits.filter((hit: ProductData) =>
            hit.categories?.some((category) => categoriesSet.has(category)),
          );

          setResults(filteredHits); // filter by categories
        } else {
          setResults(hits); // standard search
        }
      } catch (error) {
        //setError(error as string);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      performSearch();
    } else {
      setResults([]);
    }
  }, [query]);

  return { searchResults, isLoadingSearchResults, searchError };
}
